<template>
  <b-row class="match-height">
    <!-- <b-col xl="12" md="12">
      <zape-learning-statics :sendDatesPropsToAllComponent="sendDatesPropsToAllComponent" />
    </b-col> -->
    <b-col lg="12" md="12">
      <app-user-graphs-by-time @dateRange="dateRange" />
    </b-col>
    <b-col lg="6" md="6">
      <most-popular-topic :sendDatesPropsToAllComponent="sendDatesPropsToAllComponent" />
    </b-col>
    <b-col lg="6" md="6">
      <most-popular-users :sendDatesPropsToAllComponent="sendDatesPropsToAllComponent" />
    </b-col>
    <!-- <b-col lg="4" md="4"> -->
    <!-- <app-user-grade-progress :sendDatesPropsToAllComponent="sendDatesPropsToAllComponent"/> -->
    <!-- </b-col> -->

  </b-row>
</template>

<script>
import {
  BButton,
  BCard,
  BTable,
  BAvatar,
  BImg,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BDropdown,
  BRow,
  BCol,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { methods } from "vue-echarts";
import axios from "axios";
import AppUserGraphsByTime from "./components/AppUserGraphsByTime.vue";
// import zapeLearningStatics from "./components/zapeLearningStatics.vue";
import MostPopularTopic from "./components/MostPopularTopic.vue";
import MostPopularUsers from "./components/MostPopularUsers.vue";
import AppUserGradeProgress from "./components/AppUserGradeProgress.vue";


// zapeLearningStatics,
export default {
  components: {
    AppUserGradeProgress,
    AppUserGraphsByTime,
    MostPopularTopic,
    MostPopularUsers,
    BButton,
    BCard,
    BTable,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BAvatar,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      sendDatesPropsToAllComponent: {},
    };
  },

  created() { },

  methods: {
    dateRange(value) {
      // alert(0);
      // console.log(typeof(date));
      // console.log('value',value);
      this.sendDatesPropsToAllComponent = value;
    },
    exportdata: function () {
      axios({
        url: process.env.VUE_APP_API_URL + "/exportalldata",
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "data.json");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
  },
};
</script>

<style></style>
